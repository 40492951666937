@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-neutral-100 text-neutral-700;
}

.container {
  @apply px-4;
}

.text-shadow {
  text-shadow: 1px 1px 4px rgb(0 0 0 / 70%);
}
ul ul {
  list-style-type: square;
  margin-left: 30px;
  font-weight: initial;
}
ul {
  margin-bottom: 20px;
  font-weight: 600;
}
